import { Box, InputLabel } from "@mui/material";

import LogoTL from '../Assets/TrafficLight_Logo.png'
import LightExp from '../Assets/Ligh_Experiences_TL.png'

import AmorPuebla from '../Assets/PENSAR EN GRANDE@150x.png'
import GobPuebla from '../Assets/logoPuebla.png'
import GobNuevoPuebla from '../Assets/logoNuevoPuebla.png'
import SectConvParq from '../Assets/CONVENCIONES Y PARQUES@150x.png'
import { useEffect, useState } from "react";

const Footer = () => {

    const [currentDateTime, setCurrentDateTime] = useState("");

    // Función para actualizar la fecha actual
    const updateDate = () => {
        const now = new Date();
        const formattedDate = now.toISOString().slice(0, 10); // YYYY-MM-DD
        setCurrentDateTime(formattedDate);
    };

    // Configura el temporizador para actualizar a medianoche
    const setupMidnightUpdater = () => {
        const now = new Date();
        // Calcula cuánto tiempo falta para la próxima medianoche
        const timeUntilMidnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1, // Próximo día
        0, 0, 0, 0 // 12:00 AM
        ) - now;

        // Actualiza la fecha en la próxima medianoche
        setTimeout(() => {
        updateDate(); // Actualiza la fecha
        setupMidnightUpdater(); // Configura el siguiente ciclo de 24 horas
        }, timeUntilMidnight);
    };

    useEffect(() => {
        updateDate(); // Actualiza inmediatamente al montar el componente
        setupMidnightUpdater(); // Configura el temporizador para medianoche
    }, []);

    return (
        <>
        <Box sx={{display:{xs:'block', md:'none'}}}>
            <Box sx={{display:"flex", backgroundColor:"#f7f9f9", p:1, height:"auto", maxWidth:"100%", 
                flexDirection:"column"
            }}>
                <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between"
                }}>
                    <Box component={"img"} src={SectConvParq} alt="ParqJar" width={"19%"} /> 
                    <Box component={"img"} src={GobNuevoPuebla} alt="GobPuebla" width={"20%"} /> 
                    <Box component={"img"} src={AmorPuebla} alt="LogoAmorPuebla" width={"30%"} />
                </div>
                <div style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                    <Box component={"img"} src={LightExp} alt="LightExp" width={"15%"} />
                    <Box sx={{display:"flex", alignItems:"center", width:"30%", gap:1}}>
                        <label style={{fontSize:"9px"}}>Diseñado por:</label>
                        <Box component={"img"} src={LogoTL} alt="TrafficLogo" width={"70%"}/>
                    </Box>
                </div>
            </Box>
        </Box>
        <Box sx={{display:{xs:'none', md:'block'}}}>
            <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between", px:3
            }}>
                <Box component={"img"} src={SectConvParq} alt="ParqJar" width={"8%"}/>
                <Box component={"img"} src={GobNuevoPuebla} alt="GobPuebla" width={"9%"} /> 
                <Box component={"img"} src={AmorPuebla} alt="LogoAmorPuebla" width={"16%"} />
                <Box component={"img"} src={LightExp} alt="LightExp" width={"7%"} />
                <Box component={"img"} src={LogoTL} alt="TrafficLogo" width={"9%"}/>
            </Box>
        </Box>
        </>
    );
}

export default Footer;