import { Box, IconButton } from "@mui/material";

import Banner from '../Assets/BannerInicial.webp'
import { CalendarMonth } from "@mui/icons-material";
import Swal from "sweetalert2";
import Titulo from '../Assets/Titulo_festival.svg'

import axios from "axios";

const TopBar = () => {

    const DownloadAdvice = async () => {
        const response = await Swal.fire({
            title:"¿Desea continuar con la operación?",
            text:"Se descargará el programa de actividades",
            icon:"question",
            confirmButtonColor:"#27ae60",
            cancelButtonColor: "#ee4837",
            showCancelButton: true
        })
        if (response.isConfirmed) {
            const url = 'https://keycity-test.s3.us-east-1.amazonaws.com/navidad/CarteleraSemanal_16a22.pdf';

            const response = await axios.get(url , {
                responseType: 'blob'
            })

            const urldownload = window.URL.createObjectURL(response.data);
            
            const link = document.createElement('a');
            link.href = urldownload;

            link.download = 'Programa_16al22.pdf';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        }
    }

    return (
        <>
        {/*Dispositvos móviles y tablets*/}
        <Box sx={{display:{xs:'block', md:'none'}}}>
            <Box component={"img"} src={Banner} alt="Banner" sx={{height:"100%", width:"100%"}}/>
            <Box sx={{position:"absolute", top:3, right:"5%", display:"flex", flexDirection:"column",
                alignItems:"center"
            }}>
                <IconButton sx={{color:"white", border:"2px solid", width:35}}
                size="small" onClick={DownloadAdvice}>
                    <CalendarMonth sx={{color:"white"}} fontSize="small"/>
                </IconButton>
                <label style={{color:"white", fontWeight:"bold", fontSize:10}}>Descargar</label>
                <label style={{color:"white", fontWeight:"bold", fontSize:10}}>Programa</label>
            </Box>
        </Box>

        {/*Computadoras y monitores*/}
        <Box sx={{display:{xs:'none', md:'block'}}}>
            <Box sx={{backgroundImage:"linear-gradient(to right, #C54C5B, #C3181E, #B7CCC6, #36413B, #769A97)", 
                height:"100px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Box component={"img"} src={Titulo} sx={{width:{md:'50%', xl:"100%"}, height:"80px"}}/>
            </Box>
        </Box>
        </>
    );
}

export default TopBar;