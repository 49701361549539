import { Box, Button, Divider, IconButton, Modal, useMediaQuery } from "@mui/material";
import { GoogleApiWrapper, Map, Marker, Polyline } from "google-maps-react"
import { useEffect, useRef, useState } from "react";

import flagS from '../../Assets/flagStart.png'
import flagE from '../../Assets/flag.png'

//Imágenes Textura
import texture from '../../Assets/textura_verde.png'
import texturevino from '../../Assets/textura_vino.png'
import texturemaiz from '../../Assets/textura_maiz.png'

import fondoNavidad from '../../Assets/fondo_navidad.webp'

//----------------------------------------------
import imageEx1 from '../../Assets/Example1.png'
import imageEx2 from '../../Assets/Example2.png'
import imageEx3 from '../../Assets/Example3.png'

import activities from '../../Assets/MAPA ACTIVIDADES INTERACTIVAS.webp'
import horas_trineo from '../../Assets/horarios_trineo.webp'
import two from '../../Assets/2.png'

import closevial from '../../Assets/CIERRE PASO-HORIZONTAL.svg'
import closevialV from '../../Assets/CIERRE PASO-VERTICAL.svg'
import closevialD1 from '../../Assets/CIERRE PASO-DIAGONAL.svg'
import closevialD2 from '../../Assets/CIERRE PASO-DIAGONAL 2.svg'
import poli_trans from '../../Assets/POLICIA Y TRANSITO.png'
import centro_mando from '../../Assets/CENTRO DE MANDO.png'
import ambulancia from '../../Assets/AMBULANCIAS.png'
import baño from '../../Assets/BAÑOS.png'
import info from '../../Assets/modulos de informacion.png'
import estacionamiento from '../../Assets/estacionamiento.png'
import piñata from '../../Assets/Piñata.png'
import trineo from '../../Assets/trineo-santa.svg'

import muro1 from '../../Assets/muro de los deseos 1.png'
import muro2 from '../../Assets/muro de los deseos 2.png'
import tunel_d from '../../Assets/TUNEL_DINÁMICO.png'
import logoanavidad from '../../Assets/logo festival navideño.png'

import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import { Close } from "@mui/icons-material";
import axios from "axios";

const MapaGeneralGoogle = (props) => {
    let iconMarkerMuro1 = new window.google.maps.MarkerImage(
      muro1,
      null, /* size is determined at runtime */
      null, /* origin is 0,0 */
      null, /* anchor is bottom center of the scaled image */
      new window.google.maps.Size(35, 35)
    )

    let iconMarkerMuro2 = new window.google.maps.MarkerImage(
      muro2,
      null, /* size is determined at runtime */
      null, /* origin is 0,0 */
      null, /* anchor is bottom center of the scaled image */
      new window.google.maps.Size(35, 35)
    )

    let icontunel_din = new window.google.maps.MarkerImage(
      tunel_d,
      null, /* size is determined at runtime */
      null, /* origin is 0,0 */
      null, /* anchor is bottom center of the scaled image */
      new window.google.maps.Size(30, 30)
    )

    const [iconSize, setIconSize] = useState(70);
    const [iconSizeCloseVial, setIconSizeClose] = useState(30);
    const [openImageActivity, setopenActivity] = useState(false)
    const [openHorasTrineo, setopenTrineo] = useState(false)

    const mapRefGeneral = useRef(null);
    const latactual = useRef(null)
    const longactual = useRef(null)

    const Url = "https://cemitas.key-city-api.com/api/"

    const [open, setopen] = useState(false)
    const [modulselect, setmodulselect] = useState(-1)
    const [openinitialinfo, setinicial] = useState(true)
    const isMobileOrTablet = useMediaQuery('(max-width:900px)');

    //Imágenes
    const [images, setimages] = useState([
      {"url":imageEx1},
      {"url":imageEx2},
      {"url":imageEx3},
  ])

    //LightBox
    const [openLightBox, setOpenLightBox] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)

    //Espacio para coordenadas
    const array_iconosextras = [
      //Policía y Tránsito
      {"latlng": {lat:19.0536894757753, lng:-98.18537230069977}},
      {"latlng": {lat:19.05988668460473, lng:-98.18505038569829}},
      {"latlng": {lat:19.056920087840783, lng:-98.17871055239371}},
      {"latlng": {lat:19.05239826892622, lng:-98.1808189312237}},
      {"latlng": {lat:19.059001601042016, lng:-98.18107133160562}},
      //Atención de mando
      {"latlng": {lat:19.05504090953338, lng:-98.18127217847915}},
      //Ambulancias
      {"latlng": {lat:19.055144806966943, lng:-98.18112402473166}},
      {"latlng": {lat:19.058097136790355, lng:-98.18261524606653}},
      //Baños
      {"latlng": {lat:19.058182000440276, lng:-98.18540294194777}},
      {"latlng": {lat:19.05685642719532, lng:-98.18374704986537}},
      {"latlng": {lat:19.054170112485608, lng:-98.18197625916886}},
      {"latlng": {lat:19.05488561552598, lng:-98.1812023830732}},
      {"latlng": {lat:19.058308994953432, lng:-98.18183111265428}},
      //Módulo de Info
      {"latlng": {lat:19.058261082902384, lng:-98.18533890034574}},
      {"latlng": {lat:19.056660811967593, lng:-98.18336534733925}},
      {"latlng": {lat:19.053543477864576, lng:-98.18078750004585}},
      {"latlng": {lat:19.056546025959257, lng:-98.18182834649727}},
      //Estacionamiento
      {"latlng": {lat:19.06084153502188, lng:-98.18513855662314}},
      {"latlng": {lat:19.054117238821828, lng:-98.18460216774331}},
      {"latlng": {lat:19.058895240209235, lng:-98.1819510364878}},
      {"latlng": {lat:19.058596589168996, lng:-98.18155227063939}},
      //Piñata Posada
      {"latlng": {lat:19.057133749343045, lng:-98.18402979065813}},
      //Trineo Santa , 
      {"latlng": {lat:19.054743882512096, lng:-98.18111410530453}},
    ]

    //Espacio para coordenadas
    const array_closevialidades = [
      {"latlng": {lat:19.05389080128318, lng:-98.18544863109712}},
      {"latlng": {lat:19.059547762715255, lng:-98.18492020162903}},
      //{"latlng": {lat:19.051909595223254, lng:-98.18111699289011}},
      {"latlng": {lat:19.057702664969238, lng:-98.18258421247216}},
      {"latlng": {lat:19.055081565058604, lng:-98.18085639215556}},
      {"latlng": {lat:19.05691271283566, lng:-98.17845185350467}},
    ]

    const coordinates_markers = [
        {"latlng": { lat: 19.056150535773916, lng: -98.18634294433463 }, "titulo": "1. Corazón Navideño", "lugar":"Casa Puebla",
        "cuerpo":"Cuando las luces parecen respirar vida, nuestro Corazón palpita con más fuerza."}, //1
        {"latlng": { lat: 19.057247312351958, lng: -98.18736617464332 } , "titulo": "2. La luz de la Navidad | Parte 1", "lugar":"VM Fuerte de Loreto",
        "cuerpo":<>Llenos de esperanza, somos testigos de la magia y la alegría con sabor a hogar proyectada en históricos muros: posadas, villancicos, piñatas, ponche y abrazos de nochebuena, nos ilumina el corazón de ilusión. 
        <br/> <br/> <b>Horarios:</b> <br/> <b>Lunes - Jueves: 7:30 | 8:00 | 8:30 | 9:00 | 9:30 p.m.</b> <br/> <b>Viernes - Domingo:  7:30 | 8:00 | 8:30 | 9:00 | 9:30 | 10:00 p.m.</b> </>}, //2
        {"latlng": { lat: 19.058016402865203, lng: -98.18516058992103 } , "titulo": "3. Brillo de estrellas", "lugar":"Monumento a la Bandera",
        "cuerpo":"La ilusión nos lleva frente a un reno que custodia uno de los tres majestuosos árboles en cuyo interior, habita un cielo que une historias con su mágico titilar."}, //3
        {"latlng": { lat: 19.0582692549312, lng: -98.18525224666676 } , "titulo": "4. Portal Mágico", "lugar":"Acceso 2 Norte",
        "cuerpo":"Soldados y ángeles custodian un Portal para adentrarnos en un viaje lleno de magia."}, //4
        {"latlng": { lat: 19.056130640717832, lng: -98.18335392377405 } , "titulo": "5. Sendero de esperanza", "lugar":"Av. Ejército Oriente",
        "cuerpo":"Un pasaje vestido de esperanza con arcos, túneles y árboles iluminados nos invita a crear nuevas memorias: el orgullo de ser poblano se vive en cada paso."}, //5
        {"latlng": { lat: 19.053787474781853, lng: -98.1809537851239 }, "titulo": "6. Por Amor a Puebla", "lugar":"Plaza Cívica de la Victoria",
        "cuerpo":"Al llegar a un segundo árbol vestido con magnánimas esferas, se inundan nuestros corazones de esperanza, robándonos el aliento, por amor a Puebla."}, //6
        {"latlng": { lat: 19.055415125144062, lng: -98.18154461224992 }, "titulo": "7. Camino de luces", "lugar":"Calle Unidad 5 de Mayo",
        "cuerpo":"Este camino de luces nos inspira a capturar nuevas memorias: árboles, esferas y alas nos envuelven en la magia de las historias."}, //7
        {"latlng": { lat: 19.057130710336416, lng: -98.18269752559 } , "titulo": "8. La luz de la Navidad | Parte 2", "lugar":"VM Explanada Auditorio de la Reforma",
        "cuerpo":<>Este es el punto perfecto para contemplar historias de nuestras tradiciones más queridas: coloridas esferas, el olor a canela del ponche caliente, cenas y regalos se enmarcan de cielos iluminados y mágicas figuras. Y en la espera de la llegada de los reyes magos, las historias que vivimos con nuestros abuelos, se re-viven con gran emoción.
        <br/> <br/> <b>Horarios:</b> <br/> <b>Lunes - Jueves: 8:00 | 8:40 | 9:20 | 10:00 p.m</b> <br/> <b>Viernes - Domingo: 8:00 | 8:40 | 9:20 | 10:00 | 10:40 | 11:20 p.m.</b> </>}, //8
        {"latlng": { lat: 19.05744909114004, lng: -98.18254443519905 }, "titulo": "9. Constelación Navideña", "lugar":"Unidad Cívica 5 de mayo",
        "cuerpo":"Un cielo pintado de innumerables estrellas, abre paso a un nacimiento lleno de fe."}, //9
        {"latlng": { lat: 19.056744821014668, lng: -98.18179354624779 }, "titulo": "10. Árbol Monumental", "lugar":"Explanada Centro Expositor",
        "cuerpo":"Finalmente, un sendero rojo nos lleva al encuentro de un carruaje y regalos que rodean el gran árbol monumental."}, //10
        {"latlng": { lat: 19.056826351247004, lng: -98.18665112423083 } , "titulo": "Muro de los Deseos 1", "lugar":"",
        "cuerpo":"Frente al Muro de los Deseos, niños y adultos escribimos nuestras esperanzas y sueños, fundiéndose con el aire: “La Navidad es creer”."}, //M1
        {"latlng": { lat: 19.05749664845035, lng: -98.18462212673444 } , "titulo": "Muro de los Deseos 2", "lugar":"",
        "cuerpo":"A lo largo de la historia, en nuestra constante búsqueda de bendiciones, distintas culturas nos han inspirado a expresar deseos, esperanzas y oraciones de bienestar con ferviente fe y esperanza. Este Muro de los Deseos ha sido creado para ser vestido con tus mejores deseos."}, //M2
        {"latlng": { lat: 19.057426462140562, lng: -98.18646012454201 } , "titulo": "Estrella Fugaz", "lugar":"",
        "cuerpo":"Al entrar en la 'Estrella Fugaz', las luces te rodean en un instante de magia que cambia constantemente. Cada destello refleja esos momentos que, aunque breves, dejan huella. Este túnel nos recuerda que lo efímero puede perdurar en el corazón, iluminando nuestro camino mucho después de haberse ido."}, //Túnel Dinámico
    ]

    const Coordenadas_Linea = [
        { lat: 19.056150535773916, lng: -98.18634294433463 }, //Punto 1
        { lat: 19.056495325525006, lng: -98.1865253345501 },
        { lat: 19.0568553258238, lng: -98.18663798733022 },
        { lat: 19.057247312351958, lng: -98.18736617464332 }, //Punto 2
        { lat: 19.0568553258238, lng: -98.18663798733022 },
        { lat: 19.05707652281029, lng: -98.18663128180688 },
        { lat: 19.057657084257176, lng: -98.1863845185749 },
        { lat: 19.057824407267056, lng: -98.18623699707807 },
        { lat: 19.05810581376349, lng: -98.18585880560211 },
        { lat: 19.058290248844415, lng: -98.1855248705755 },
        { lat: 19.05830799517329, lng: -98.18542696994929 },
        { lat: 19.05830229099845, lng: -98.18538204294937 },
        { lat: 19.05826362936928, lng: -98.18525463801943 },
        { lat: 19.054370800768442, lng: -98.18191059393682 }, //Punto 4,
        { lat: 19.054369533143554, lng: -98.18175368470791 },
        { lat: 19.054413899988344, lng: -98.18163030309157 },
        { lat: 19.0544722106806, lng: -98.18155520123815 },
        { lat: 19.054601629587705, lng: -98.1814472212775 },
        { lat: 19.053787474781853, lng: -98.1809537851239 }, //Punto 6,
        { lat: 19.054601629587705, lng: -98.1814472212775 },
        { lat: 19.055012217431404, lng: -98.181359399979 },
        { lat: 19.055095880290406, lng: -98.18136074108354 },
        { lat: 19.055204895468304, lng: -98.18137951654751 },
        { lat: 19.05526193826503, lng: -98.18139695090632 },
        { lat: 19.056496593137517, lng: -98.18206347985101 }, //Punto 7
        { lat: 19.056857806223462, lng: -98.18229289863649 },
        { lat: 19.057130710336416, lng: -98.18269752559 }, //Punto 8,
        { lat: 19.05744909114004, lng: -98.18254443519905 }, //Punto 9,
        { lat: 19.057488529615842, lng: -98.18211959496037 },
        { lat: 19.05710848573155, lng: -98.18218028642305 },
        { lat: 19.056744821014668, lng: -98.18179354624779 }, //Punto 10,

    ]

    const mapStyles = [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#b2b2b1"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.business",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#b2b2b1"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]

    useEffect(() => {
        if (mapRefGeneral.current) {
            const map = mapRefGeneral.current.map;

            map.setOptions({
                maxZoom: 18,   // Establece el zoom máximo
                minZoom: 15,    // (Opcional) Establece el zoom mínimo
              });

            map.addListener('mousemove', () => {
                latactual.current = map.center.lat();
                longactual.current = map.center.lng();
              });
            //pruebaRuta()
        }

        console.clear()
    },[])

    const abrirMaps = () => {
        window.open("https://www.google.es/maps?q=19.055968800786136,-98.18587812084634", "_blank");
    }

    const handleZoomChanged = () => {
      if (mapRefGeneral.current) {
        let tamaño_final = 0
        let tamaño_final2 = 0

        const zoomLevel = mapRefGeneral.current.map.getZoom();

        //Bloque para zoom CierreVíal
        switch (zoomLevel) {
          case 15:
            tamaño_final2 = 30;
            break;

          case 16:
            tamaño_final2 = 45;
            break;

          case 17:
            tamaño_final2 = 60;
            break;

          case 18:
            tamaño_final2 = 75;
            break;
        
          default:
            tamaño_final2 = 45;
            break;
        }

        //Bloque para zoom interactivo
        if (zoomLevel < 16) {
          tamaño_final = 70
        } else if (zoomLevel === 16) {
          tamaño_final = 110
        } else if (zoomLevel === 17) {
        tamaño_final = 240
        }
        else {
          tamaño_final = 510
        }

        setIconSize(tamaño_final);
        setIconSizeClose(tamaño_final2)
      }
    };

    const changeiconextra = (index) => {
      if (index <= 4 ) {
        return poli_trans
      } else if (index === 5) {
        return centro_mando
      } else if (index > 5 && index < 8) {
        return ambulancia
      } else if (index > 7 && index < 13) {
        return baño
      } else if (index > 12 && index < 17) {
        return info
      } else if (index > 16 && index < 21) {
        return estacionamiento
      } else if (index === 21) {
        return piñata
      } else if (index === 22) {
        return trineo
      }
    }

    const cambioicono = (index_array) => {
      if (index_array <= 2) {
        return closevial
      } else if (index_array > 2){
        return closevialD2
      }
    }

    const openModal = async (id_modal) => {
        setmodulselect(id_modal)

        await axios.get(Url + "imagen_navidad?modulo=" + (id_modal + 1))
        .then(res => {
          if (res.data.data.length > 0) {
            setimages(res.data.data)
          }
        })
        .catch(err => {
          console.log(err)
        })

        setopen(true)
    }
    const closeModal = () => {
        setopen(false)
    }

    const openimages = (index_foto) => {
        setPhotoIndex(index_foto)
        setOpenLightBox(true)
    }

    return(
        <>
        <Map
            ref={mapRefGeneral}
            google={props.google}
            zoom={15}
            initialCenter={{lat: 19.056587041529777, lng : -98.18329248544838}}
            styles={mapStyles}
            disableDefaultUI={true}
            onZoomChanged={handleZoomChanged}
        >
            <Marker position={{ lat:19.055968800786136, lng:-98.18587812084634}}
                icon={{
                    url: flagS,
                    scaledSize: new props.google.maps.Size(45, 45),  // Tamaño del icono
                  }}
                onClick={abrirMaps}
            />
            <Marker position={{ lat:19.056940788912478, lng:-98.18142673050777}}
                icon={{
                    url: flagE,
                    scaledSize: new props.google.maps.Size(45, 45),  // Tamaño del icono
                  }}
            />

            <Marker position={{ lat:19.055509759038088, lng:-98.18033509591542}}
                icon={{
                  url: two,
                  scaledSize: new props.google.maps.Size(iconSize, iconSize),  // Tamaño del icono
                }}
                onClick={()=>setopenActivity(true)}
            />

            {array_iconosextras.map((markerextra, indexextra) => (
              <Marker key={indexextra} position={markerextra.latlng}
              icon={{
                url: changeiconextra(indexextra),
                scaledSize: new props.google.maps.Size(30, 30)
              }}
              onClick={indexextra === 22 && (()=>setopenTrineo(true))}
              />
            ))}

            {array_closevialidades.map((markerclosevial, indexclose) => (
              <Marker key={indexclose} position={markerclosevial.latlng} 
              icon={{
                url: cambioicono(indexclose),
                scaledSize: new props.google.maps.Size(iconSizeCloseVial, iconSizeCloseVial)
              }}
              />
            ))}

            {coordinates_markers.map((marker, indexMarker) => (
                <Marker key={indexMarker} position={marker.latlng}
                  label={![10,11,12].includes(indexMarker) &&
                    {
                      text: (indexMarker + 1).toString(),
                      color: "white",
                      fontSize: "20px",
                    } || "" }
                  icon={indexMarker === 10 && iconMarkerMuro1 || 
                    indexMarker === 11 && iconMarkerMuro2 || 
                    indexMarker === 12 && icontunel_din ||undefined}

                onClick={() => openModal(indexMarker)}
                />
            ))}

          <Polyline
            path={Coordenadas_Linea}
            strokeColor="#196f3d"
            strokeOpacity={1.0}
            strokeWeight={4}
            geodesic={true}
          />
        </Map>

        <Modal open={openImageActivity} onClose={()=>setopenActivity(false)} sx={{display:"flex", 
          justifyContent:"center", alignItems:"center", width:"100%", height:"100vh"}}>
          <Box sx={{width:{xs:"100%", md:"30%"}, height:"auto", backgroundColor:"white", flexDirection:"column",
            display:"flex", alignItems:"end"
          }}>
            <IconButton color="error" onClick={()=>setopenActivity(false)}>
              <Close color="error"/>
            </IconButton>
            <Box component={"img"} src={activities} sx={{width:"100%", height:"90vh"}}/>
          </Box>
        </Modal>

        <Modal open={openHorasTrineo} onClose={()=>setopenTrineo(false)} sx={{display:"flex", 
          justifyContent:"center", alignItems:"center", width:"100%", height:"100vh"}}>
          <Box sx={{width:{xs:"100%", md:"50%"}, height:{xs:"65vh", md:"auto"}, backgroundColor:"white", flexDirection:"column",
            display:"flex", alignItems:"end"
          }}>
            <IconButton color="error" onClick={()=>setopenTrineo(false)}>
              <Close color="error"/>
            </IconButton>
            <Box component={"img"} src={horas_trineo} sx={{width:"100%", height:"90vh"}}/>
          </Box>
        </Modal>

        {isMobileOrTablet &&
          <Modal open={openinitialinfo} onClose={()=>setinicial(false)}>
              <Box sx={{width:"calc(100% - 40px)", height:"calc(100vh - 40px)", flexDirection:"column",
                display:"flex", p:"20px", gap:2.5, alignItems:"center", backgroundImage:"url("+fondoNavidad+")",
                backgroundPosition:"top", backgroundRepeat:"no-repeat", backgroundSize:"115% calc(98vh - 40px)",
              }}>
                <Box sx={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                  <IconButton onClick={()=>setinicial(false)} sx={{border:"1px solid white", backgroundColor:"#e74c3c"}}
                  size="small">
                    <Close sx={{color:"white"}}/>
                  </IconButton>
                </Box>

                <Box sx={{display:"flex", justifyContent:"center"}}>
                  <Box component={"img"} src={logoanavidad} sx={{width:190}}/>
                </Box>

                <em style={{textAlign:"justify", lineHeight:2, fontSize:17, width:"85%"}}>
                Este año, la Navidad en Puebla conecta el pasado con el presente: la zona de los fuertes abre sus 
                puertas a un Sendero Iluminado cuya esencia entrelaza historias, emociones y recuerdos
                </em>
              </Box>
          </Modal>
        }

        {isMobileOrTablet &&
          <Box sx={{display:"flex", gap:2, p:1, position:"absolute", width:"90%", backgroundColor:"white",
            height:"auto", bottom:5, left:0, right:0, margin:"auto", borderRadius:2, overflowX:"auto"
          }}>
            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={estacionamiento} width={23} height={23} />
              <label style={{fontSize:10}}>ESTACIONAMIENTO</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={baño} width={23} height={23} />
              <label style={{fontSize:10}}>BAÑOS</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={info} width={23} height={23} />
              <label style={{fontSize:10}}>MÓDULOS DE INFORMACIÓN</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={centro_mando} width={23} height={23} />
              <label style={{fontSize:10, width:55}}>CENTRO DE MANDO</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={ambulancia} width={23} height={23} />
              <label style={{fontSize:10}}>AMBULANCIAS</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={poli_trans} width={23} height={23} />
              <label style={{fontSize:10}}>POLICÍA Y TRÁNSITO</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={closevial} width={20} height={15} />
              <label style={{fontSize:10}}>CIERRE DE VIALIDADES</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={tunel_d} width={23} height={23} />
              <label style={{fontSize:10}}>TÚNEL DINÁMICO</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={piñata} width={23} height={23} />
              <label style={{fontSize:10}}>POSADAS</label>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:6}}>
              <img src={trineo} width={30} height={30} />
              <label style={{fontSize:10}}>RECORRIDO TRINEO DE SANTA</label>
            </div>
          </Box>
        }

        {modulselect >= 0 &&
            <Modal open={open} onClose={closeModal} sx={{display:"flex", alignItems:"center", 
            justifyContent:"center"}}>
                <Box sx={{width:{xs:"90%", md:"50%"}, height:"80vh", backgroundColor:"white", display:"flex", flexDirection:"column"}}>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",
                        padding:20, backgroundImage:"url(" + ((modulselect % 2 === 0) ? texture : texturevino) + ")", 
                        backgroundSize:"cover", height:100
                    }}>
                        <h3 style={{color:"white"}}>{coordinates_markers[modulselect].titulo} <br/>
                        <label style={{color:"white", fontSize:"14px", fontWeight:"normal"}}>
                          {coordinates_markers[modulselect].lugar}</label>
                        </h3>
                        
                        <IconButton sx={{border:"2px solid", mx:1, color:((modulselect % 2 === 0) ? "#ec7063" : "white")}} 
                        size="small" onClick={closeModal}>
                          <Close fontSize="small" sx={{color:((modulselect % 2 === 0) ? "#ec7063" : "white")}}/>
                        </IconButton>
                    </div>
                    <Divider sx={{width:"100%"}} />
                    {coordinates_markers[modulselect].cuerpo ?
                        <Box sx={{p:2, overflowY:"auto", overflowX:"hidden", height:"50vh",
                          backgroundImage:"url("+texturemaiz+")", backgroundSize:"contain",
                          backgroundRepeat:{xs:"no-repeat", sm:"repeat"}, backgroundPosition:"right"
                        }}>
                            <label style={{color:"black", fontFamily:"serif", fontSize:"22px"}}>
                              {coordinates_markers[modulselect].cuerpo}</label>
                        </Box>
                        :
                        ""
                    }
                    
                    <h4 style={{marginLeft:10}}>Momentos:</h4>
                    <Box sx={{display:"flex", gap:2, alignItems:"center", overflowY:"hidden", m:2, 
                    overflowX:"auto",
                        }}>
                        {images.map((imagen, indeximagen) => (
                            <Box component={"img"} key={indeximagen} src={imagen.url} alt="Foto" 
                            sx={{width:{xs:"26%", sm:"23%"}, height:{xs:"auto", sm:"95%"}}}
                            onClick={() => openimages(indeximagen)} />
                        ))}
                    </Box>

                    {openLightBox && (
                        <Lightbox
                            mainSrc={images[photoIndex].url}
                            nextSrc={images[(photoIndex + 1) % images.length].url}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
                            onCloseRequest={() => setOpenLightBox(false)}
                            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                            reactModalStyle={{
                                overlay: {
                                  zIndex: 9999, // Aquí defines el z-index del overlay
                                },
                                content: {
                                  zIndex: 9999, // Aquí defines el z-index del contenido
                                }
                              }}
                        />
                    )}
                </Box>
            </Modal>
        }
        </>
    )
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyAiXWOxSPvO0AViX6hlFjBTP3dg5NH83FQ',
  })(MapaGeneralGoogle);