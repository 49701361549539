import { Box } from "@mui/material";
import MapaGoogleGeneral from "../Components/MapaGeneralGoogle/MapaGoogleGeneral";
import Footer from "../Layers/Footer";
import TopBar from "../Layers/TopBar";
import './MapaInteractivo.scss';

const MapaInteractivo = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height:{xs:'90.2vh', md:'100vh'} }}>
            <TopBar />
            <div style={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
                <MapaGoogleGeneral />
            </div>
            <Footer />
        </Box>
    );
};

export default MapaInteractivo;