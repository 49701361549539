import { Alert, Box, Button, Card, Checkbox, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import './AdmonImages.scss';
import { Delete, UploadFile } from "@mui/icons-material";
import Swal from "sweetalert2";

const AdmonImages = () => {
    
    const Url = "https://cemitas.key-city-api.com/api/"

    const [isCharging, setisCharging] = useState(false)
    const [selectModul, setselectModul] = useState(" ")
    const [photos, setphotos] = useState([])
    const [total_photos, settotal] = useState(0)

    const [foradd, setforadd] = useState([])
    const [fordelete, setfordelete] = useState([])
    const [action, setaction] = useState("")

    const inputFilesref = useRef()
    const [access_grantied, setaccess] = useState(false) 

    const modulos = [
        {"id":1, "nombre":"1. Corazón Navideño - Casa Puebla"},
        {"id":2, "nombre":"2. La luz de la Navidad | Parte 1 - VM Fuerte de Loreto"},
        {"id":3, "nombre":"3. Brillo de estrellas - Monumento a la Bandera"},
        {"id":4, "nombre":"4. Portal Mágico - Acceso 2 Norte"},
        {"id":5, "nombre":"5. Sendero de esperanza - Av. Ejército Oriente"},
        {"id":6, "nombre":"6. Por Amor a Puebla - Plaza Cívica de la Victoria"},
        {"id":7, "nombre":"7. Camino de luces - Calle Unidad 5 de Mayo"},
        {"id":8, "nombre":"8. La luz de la Navidad | Parte 2 - VM Explanada Auditorio de la Reforma"},
        {"id":9, "nombre":"9. Constelación Navideña - Unidad Cívica 5 de mayo"},
        {"id":10, "nombre":"10. Árbol Monumental - Explanada Centro Expositor"},
        {"id":11, "nombre":"Muro de los Deseos 1"},
        {"id":12, "nombre":"Muro de los Deseos 2"},
        {"id":13, "nombre":"Estrella Fugaz"},
    ]

    useEffect(() => {
        const response = window.prompt("Ingrese contraseña")
        if (response === "123") {
            setselectModul(" ")
            setphotos([])
            setaccess(true)
        }
    },[])

    //Mostrar las Imágenes
    const handleChangeSelectModulo = async (event) => {
        const value = event.target.value
        setisCharging(true)

        setselectModul(value)
        await axios.get(Url + "imagen_navidad?modulo=" + value)
        .then(res => {
            setphotos(res.data.data)
            settotal(res.data.data.length)
            setisCharging(false)
        })
        .catch(err => {
            console.log(err)
        })
    }

    //Agregar Nuevas Imágenes
    const handleNewFiles = async (event) => {
        const total_event = event.target.files.length

        if ((total_photos + total_event) <= 9) {
            const arrayFiles = event.target.files;    
            setaction("Agregar")
            setforadd(Array.from(arrayFiles))    
        } else {
            Swal.fire('Solo puedes tener máximo 9 fotos', '',"warning")
        }
    };

    //Seleccionar elementos a Eliminar
    const handleDeleteImages = (event) => {
        const valueid = event.target.value
        const copydelete = [...fordelete]

        if (!copydelete.includes(valueid)) {
            copydelete.push(valueid)
        } else {
            copydelete.splice(copydelete.indexOf(valueid),1)
        }
        setfordelete(copydelete)
    }

    //Confirmar accion
    const confirmation = async () => {

        setisCharging(true);

        if (action === "Agregar") {
            try {
                const promesas = foradd.map((file) => {
                    const formData = new FormData();
                    formData.append("imagen", file);
                    formData.append("modulo", selectModul);
    
                    return axios.post(Url + "imagen_navidad", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                });
    
                await Promise.all(promesas);
    
                const res = await axios.get(Url + "imagen_navidad?modulo=" + selectModul);
                setphotos(res.data.data);
                setforadd([])
    
                Swal.fire("Archivos Subidos correctamente", "", "success");
            } catch (err) {
                console.error(err);
                Swal.fire("Error al subir archivos", "", "error");
            } finally {
                setisCharging(false);
            }
            
        } else {
            try {
                const promesas = fordelete.map((file) => {
                    return axios.delete(Url + "imagen_navidad?id="+ parseInt(file));
                });
    
                await Promise.all(promesas);
    
                const res = await axios.get(Url + "imagen_navidad?modulo=" + selectModul);
                setphotos(res.data.data);
                setfordelete([])
    
                Swal.fire("Archivos Eliminados correctamente", "", "success");
            } catch (err) {
                console.error(err);
                Swal.fire("Error al eliminar archivos", "", "error");
            } finally {
                setisCharging(false);
            }
        }

        setaction("")
    }

    return (
        <>
        {access_grantied ?
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", p:1, gap:10,
            background:"linear-gradient(to bottom right, #f5b7b1, #a9dfbf)", height:"calc(100vh - 16px)"
        }}>
            <div style={{display:"flex", gap:10, alignItems:"center", width:"40%", marginTop:"30px"}}>
                <label>Módulo:</label>
                <Select
                    id="SelectModulo"
                    onClose={() => {
                        setTimeout(() => {
                        document.activeElement.blur();
                        }, 0);
                    }}
                    value={selectModul}
                    onChange={handleChangeSelectModulo}
                    variant="standard"
                    sx={{fontSize:"17px", fontWeight:"bold"}}
                    fullWidth
                >
                    <MenuItem value=" " disabled>{"-Seleccione-"}</MenuItem>
                    {modulos.map((modulo, indexmodulo) => (
                        <MenuItem key={indexmodulo} value={modulo.id}>{modulo.nombre}</MenuItem>
                    ))}
                </Select>
            </div>
        
            {selectModul >= 1 &&
            <>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between", my:"-2%",
                    width:"90%"
                }}>
                    <div style={{display:"flex", alignItems:"center", gap:10}}>
                        {/*Subir Archivos*/}
                        {["Agregar",""].includes(action) &&
                            <Button endIcon={<UploadFile />} variant="contained"
                            onClick={() => inputFilesref.current.click()} disabled={photos.length >= 9}>
                                Agregar fotos
                            </Button>
                        }
                        <input type="file" multiple ref={inputFilesref} style={{display:"none"}} 
                        onChange={handleNewFiles} accept="image/webp"/>

                        {/*Eliminar Archivos*/}
                        {["Eliminar",""].includes(action) &&
                            <Button endIcon={<Delete />} variant="contained" color="error"
                            onClick={() => setaction("Eliminar")} disabled={action === "Eliminar"}>
                                Eliminar fotos
                            </Button>
                        }

                        {["Agregar","Eliminar"].includes(action) &&
                        <div style={{marginLeft:30, gap:10, display:"flex"}}>
                            <Button color="success" variant="contained" onClick={confirmation} 
                                disabled={(foradd.length < 1 && action === "Agregar") 
                                || (fordelete.length < 1 && action === "Eliminar")}>
                                Confirmar
                            </Button>

                            <Button color="error" variant="contained" onClick={()=> setaction("")}>
                                Cancelar
                            </Button>
                        </div>
                        }
                    </div>

                    {photos.length >= 9 &&
                    <Alert severity="info">Solo 9 imágenes por módulo</Alert>}
                </Box>
                
                <Card sx={{p:5, width:"90%", maxHeight:'70vh', overflowY:"auto", display:"flex",
                    flexWrap:"wrap", justifyContent:"center", gap:5}}>
                    {photos.map((photo, index_photo) => (
                        <Box key={index_photo} sx={{display:"flex", alignItems:"flex-start", gap:1, width:"18%"}}>
                            {action === "Eliminar" &&
                            <Checkbox value={photo.id} onChange={handleDeleteImages} sx={{width:"4%"}}/>}
                            <Box  component={"img"} src={photo.url} alt={"Photo"+index_photo}
                            width={"100%"}/>
                        </Box>
                    ))}
                </Card>
            </>
            }
            
            {isCharging && <div className="loader" /> }
        </Box>
        :
        <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
            <h1>Error en la página</h1>
        </div>
        }
        </>
    )
}

export default AdmonImages;